@import '../../../../Index.scss';
.wrapper {
  box-shadow: 0px 2px 2px 0px $secondary-color;
  z-index: 1;
  .container {
    display: flex;
    padding: 10px 35px 10px 35px;
    justify-content: space-between;
    .left {
      display: flex;
      justify-content: center;
      img {
        max-width: 100%;
        max-height: 40px;
      }
    }
    .right {
      align-items: center;
      align-content: center;
      display: flex;
      a {
        text-decoration: none;
      }
      img{
        margin-left: 15px;
        height: 25px;
      }
      select {
        min-width: 0;
        outline: none;
        border: 0;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        border: 0;
        border-radius: 0;
      }
    }
  }
}
@media (max-width: $larger-mobile-devices) {
  .wrapper {
    .container {
      flex-direction: column;
      .left img {
        height: auto;
      }
      .right {
        align-self: center;
      }
    }
  }
}
