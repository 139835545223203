@import '../../../Index.scss';

.wrapper {
  display: flex;
  padding: 20px;
  max-width: 1200px;
  flex-grow: 1;
  .left {
    width: 350px;
    margin-right: 15px;
    flex-shrink: 0;
    .categoryContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .category {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 10px;
        img {
          width: 25px;
        }
        span {
          margin-left: 5px;
        }
      }
      .category.selected {
        margin-left: 10px;
        span {
          color: $secondary-color;
          font-weight: 600;
        }
      }
      .category:hover {
        span {
          color: $secondary-color;
        }
      }
    }
  }
  .right {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .productsContainer {
      margin-top: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .noResults {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 300px;
        }
      }
      .product {
        max-width: 100%;
        margin-bottom: 10px;
        padding: 10px;
        display: flex;
        box-shadow: 0 4px 8px 0 rgba(202, 28, 90, 0.2);
        border-radius: 5px;
        align-items: center;
        cursor: pointer;
        span {
          margin-top: 2.5px;
          margin-bottom: 2.5px;
        }
        .promotion {
          font-size: 60%;
          text-decoration: underline;
          cursor: pointer;
        }
        .logo {
          width: 130px;
          max-height: 100px;
          margin-right: 10px;
          flex-shrink: 0;
        }
        .details {
          width: 100%;
          max-width: 100%;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          align-content: center;
          align-items: center;
          justify-content: space-around;
          .name {
            font-weight: 600;
            font-size: 120%;
          }
          .amount {
            font-weight: 600;
          }
          .cost {
            font-weight: 400;
            span {
              font-size: 80%;
            }
          }
          .countriesContainer {
            font-size: 80%;
          }
        }
      }
      .product:hover {
        animation-name: selection;
        animation-duration: 0.25s;
        box-shadow: 0 14px 28px rgba(202, 28, 90, 0.35), 0 10px 10px rgba(202, 28, 90, 0.4);
      }
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
  .wrapper {
    max-width: 100%;
    flex-direction: column;
    .left {
      margin: 0 0 15px 0;
      width: 100%;
      .categoryContainer {
        flex-direction: row;
        flex-wrap: wrap;
        .category {
          padding: 0 5px 0 5px;
        }
        .category.selected {
          span {
            color: $secondary-color;
            font-weight: 600;
            font-size: 120%;
          }
        }
      }
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
  .wrapper {
    .left {
      .categoryContainer {
        flex-wrap: nowrap;
        flex-direction: column;
      }
    }
    .right {
      .productsContainer {
        .product {
          flex-direction: column;
        }
      }
    }
  }
}
