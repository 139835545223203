@import "../../../../Index.scss";

.wrapper {
  display: flex;
  flex-shrink: 0;
  background-color: $secondary-color;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  .upper {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    font-weight: 300;
    text-align: center;
    ul {
      padding: 10px;
      margin: 0;
      li {
        display: inline;
        color:white;
        margin-left: 5px;
        a {
          color:white;
          font-size: 90%;
        }
      }
      li:first-child {
        margin-left: 0;
      }
    }
  }
  .lower {
    display: flex;
    justify-content: center;
    font-weight: 100;
    padding: 10px;
    span {
      text-align: center;
      font-size: 90%;
      color:white;
      a {
        color:white;
        font-size: 90%;
      }
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
  .wrapper {
    .upper {
      ul {
        li {
          display: block;
        }
        li.spacer {
          display: none;
        }
      }
    }
  }
}
