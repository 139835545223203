@import "../../../Index.scss";
.modal {
  max-width: 80%;
  min-width: 50%;
  button{
    cursor: pointer;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    padding: 25px 10px 10px 10px;
    .body {
      display: flex;
      flex-direction: column;
      .logo {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          max-height: 100px;
          max-width: 200px;
        }
      }
      .name {
        margin-top: 15px;
        font-size: 120%;
        font-weight: 600;
        text-align: center;
      }
      .details {
        margin-top: 5px;
      }
      .countriesWrapper {
        margin-top: 5px;
        margin-bottom:10px;
        .label {
          font-weight: 600;
        }
        .container {
          margin-top: 5px;
          span.single {
            font-size: 100%;
          }
          span.multiple {
            font-size: 60%;
          }
        }
      }
      .infoWrapper {
        margin-top: 10px;
      }
      .info {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        .label {
          font-weight: 600;
        }
        .value {
          margin-top: 5px;
        }
      }
    }
    .formField {
      display: flex;
      flex-direction: column;
      .label {
        font-weight: 600;
      }
      .inputWrapper {
        display: flex;
        margin-top: 5px;
        flex-direction: column;
        .info {
          margin-top: 2px;
          font-size: 60%;
          margin-bottom: 0;
        }
        .inputControl {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          select{
            appearance: none;
            margin:0;
            padding:0;
            box-sizing: border-box;
            border:0;
            border-bottom: 1px solid black;
            border-radius: 0;
            min-width: 0;
            width: 100%;
          }
          input {
            padding:0;
            min-width: 0;
            width: 100px;
            outline: none;
            background-color: transparent;
            border-radius: 0px;
            border: 0;
            border-bottom: 1px solid black;
          }
          .detail {
            margin-left: 5px;
          }
        }
      }

      .inputWrapper.error {
        .inputControl {
          input {
            border-bottom: 1px solid red;
            color: red;
          }
          .detail {
            color: red;
          }
        }
        .info {
          color: red;
        }
      }
    }
    .beneficiaryForm {
      .beneficiaryFormLabel {
        font-weight: 600;
      }
      .formFieldContainer {
        margin-top:5px;
        input {
          width: 1000% !important;
        }
        .label {
          font-weight: 400 !important;
        }
      }
    }
    .submit {
      display: flex;
      margin-top:20px;
      justify-content: center;
      button{
        padding:10px;
        background: transparent;
        border: 0px;
        background-color: $primary-color;
        color:white;
        border-radius: 5px;
      }
    }
  }
}
