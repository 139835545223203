@import '../../../Index.scss';
.wrapper{
  display: flex;
  flex-direction: column;
  height: 100vh;
  .cookiebanner{
    display: flex;
    background-color: $primary-color !important;
    height: auto !important;
    justify-content: space-between;
    align-items: center;
    text-align: inherit !important;
    padding: 40px !important;
    width:inherit !important;
    span{
      font-size: 80%;
      line-height: inherit !important;
      a{
      font-size: 80%;
        color: white !important;
      }
    }
      button{
        position: inherit !important;
        background-color: white !important;
        top: inherit !important;
        right: inherit !important;
        line-height: inherit !important;
        margin: 0 !important;
        padding:10px !important;
        flex-grow: 0;
        flex-shrink: 0;

      }
  }
  .container{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .main{
      flex: 1 0 auto;
      display: flex;
      justify-content: center;
      .error{
        padding: 10px;
      }
    }
  }
}
