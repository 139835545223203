@import "../../../Index.scss";
.wrapper {
  max-width: 100%;
  h1 {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    padding: 20px;
  }
  .welcome {
    display: flex;
    padding: 10px;
    flex-direction: column;
    height: 70vh;
    min-height: 600px;
    align-items: center;
    background-image: url("/resources/img/background.jpg");
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
    h1,
    h3 {
      text-align: center;
      text-shadow: 1px 1px 1px #fff;
    }
    h1 {
      padding: 0;
      font-size: 250%;
      font-weight: 400;
      margin-top: 30px;
    }
    h3 {
      font-size: 130%;
      margin-top: 25px;
      font-weight: 300;
      margin-bottom: 60px;
    }
    .disclosure {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      margin-right: 60px;
      width: 100%;
      .wrapper {
        background-color: transparent;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 500px;
        }
        .downloadImgs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          img {
            margin-top: 15px;
            width: 200px;
          }
        }
        .description{
          width: 650px;
          margin-top:10px;
          span{
            a{
              font-size: 100%;
            }
            font-weight: 600;
            display: inline-block;
            color: $primary-text-color;
            font-size: 140%;
            text-align: center;
          }
        }
     
      }
    }
  }
  .bestSellers {
    padding: 0 0 60px 0;
    display: flex;
    flex-direction: column;
    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-around;
      .item {
        cursor: pointer;
        padding: 20px;
        img {
          width: 200px;
          max-height: 150px;
        }
      }
      .item:hover {
        img {
          filter: drop-shadow(-5px 10px 5px $primary-color);
        }
      }
    }
  }
  .disclosure {
    display: none;
    margin-top: 20px;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    .wrapper {
      display: flex;
      padding: 15px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 500px;
        max-width: 100%;
      }
      .downloadImgs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        img {
          margin-top: 15px;
          width: 200px;
        }
      }
      h4 {
        font-size: 180%;
        text-align: center;
      }
    }
  }

  .disclaimer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 60px 0;
    img {
      margin-top: 40px;
      max-width: 100%;
      width: 500px;
    }
    .button {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-self: center;
      padding: 15px 20px;
      cursor: pointer;
      border: 1px solid $primary-color;
      border-radius: 8px;
      font-size: 160%;
      font-weight: 400;
    }
  }

  .references {
    display: flex;
    flex-direction: column;
    padding: 0 0 60px 0;
    .products {
      display: flex;
      margin-top: 40px;
      justify-content: space-around;
      .product {
        position: relative;
        margin: 10px 20px 0 20px;
        .overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: 100%;
          width: 100%;
          opacity: 0;
          transition: 0.5s ease;
          background-color: gray;
        }
        .title {
          text-align: center;
          color: white;
          text-decoration: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 2vw;
          margin-bottom: 10%;
          font-weight: 600;
          text-shadow: 1px 1px 1px black;
          transition: 0.5s ease;
        }
        .detail {
          text-align: center;
          color: white;
          text-decoration: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
          font-size: 100%;
          font-weight: 400;
          opacity: 0;
          text-shadow: 1px 1px 1px black;
          transition: 0.6s ease-in-out;
        }
        img {
          max-width: 100%;
        }
      }
      .product:hover {
        .title {
          margin-bottom: 25%;
        }
        .detail {
          opacity: 1;
          margin-bottom: 10%;
        }
        .overlay {
          opacity: 0.3;
        }
      }
    }
  }

  .categoryContainer {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 0;
    .categories {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 40px;
      .category {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        img {
          width: 30px;
        }
        span {
          margin-left: 5px;
        }
      }
      .category:hover {
        span {
          color: $secondary-color;
        }
      }
    }
  }
}

@media screen and(max-width: $larger-mobile-devices), screen and(max-height:700px) {
  .wrapper {
    .welcome {
      h1 {
        margin-top: 20px;
        font-size: 200%;
      }
      h3 {
        margin-bottom: 20px;
      }
      .searchbox {
        .inputs {
          flex-direction: column;
          input {
            padding-left: 0;
          }
        }
      }
      .disclosure {
        display: none;
      }
    }
    .disclosure {
      display: flex;
      .descriptionResponsive{
        margin-top:10px;
        span{
          a{
            font-size: 100%;
          }
          font-weight: 600;
          display: inline-block;
          color: $primary-text-color;
          font-size: 140%;
          text-align: center;
        }
      }
    }
    .references {
      .products {
        flex-direction: column;
        align-items: center;
        .product {
          .title {
            font-size: 150%;
            margin-bottom: 10%;
          }
          .detail {
            opacity: 1;
            margin-bottom: 2%;
          }
        }
        .product:hover {
          .title {
            margin-bottom: 10%;
          }
          .detail {
            opacity: 1;
            margin-bottom: 2%;
          }
          .overlay {
            opacity: 0.2;
          }
        }
      }
    }
  }
}

@media (max-width: $smaller-mobile-devices) {
}
