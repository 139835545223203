@import '../../../Index.scss';

.wrapper{
  padding:40px;
  overflow: scroll;
  *{
    text-align: justify;
  }
  h1{
    font-size: 180%;
  }
  span.strong{
    font-weight: 600;
  }
}


