@import './resources/normalize';
@import url('https://fonts.googleapis.com/css?family=Muli:400,600,700&display=swap');

$primary-color: #3964af;
$secondary-color: #ca1c5a;
$primary-text-color: #303030;

$larger-mobile-devices: 860px;
$smaller-mobile-devices: 475px;

* {
  color: $primary-text-color;
  font-family: 'Muli', sans-serif;
  font-size: 16px;
}

.spinner {
  display: flex;
  justify-content: center;
  height: 60px;
  align-items: center;
  div {
    color: $secondary-color;
  }
}
