@import '../../../Index.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:20px;
  max-width: 90%;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 160%;
      font-weight: 500;
      margin-top: 40px;
      margin-bottom: 10px;
      text-align: center;
    }
    h2 {
      font-size: 120%;
      font-weight: 300;
      margin-bottom: 30px;
      text-align: center;
    }
    h3 {
      font-size: 100%;
      font-weight: 300;
      margin-bottom: 15px;
      text-align: center;
    }
    .disclosure {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .wrapper {
        background-color: transparent;
        display: flex;
        padding: 15px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width:500px;
          max-width: 100%;
        }
        .downloadImgs {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          img {
            margin-top: 15px;
            width: 200px;
          }
        }
        h4 {
          color:$primary-text-color;
          font-size: 180%;
          text-align: center;
        }
      }
    }
    .payment{
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom:20px;
      span{
        margin-top:10px;
      }
      a{
        margin-top:10px;
      }
      img{
        width: 200px !important;
        max-width: 90%;
        height: auto !important;
      }
      canvas{
        width: 350px !important;
        max-width: 90%;
        height: auto !important;
      }
    }
  }
}